/* 文件预览，点击查看打开此页面（针对onlyOffice预览，其他格式打不开） */
<template>
  <div>
    <div style="height:100vh">
      <div id="placeholder" style="height:100%"></div>
    </div>
  </div>
</template>

<script>
import { getFileType } from 'assets/js/uploadFile';
export default {
  data() {
    return {
      config: {},
      commomPath: '/web-apps/apps/api/documents/api.js',
      develepmentAddress: 'http://192.168.0.233:9999'
    };
  },
  created() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    // 生产环境
    if (process.env.NODE_ENV === 'production') {
      s.src = `${process.env.VUE_APP_PREVIEW}/web-apps/apps/api/documents/api.js`;
    } else {
      s.src = `${this.develepmentAddress}${this.commomPath}`;
    }
    document.body.appendChild(s);
  },
  methods: {
    getEdit() {
      this.$nextTick(() => {
        new DocsAPI.DocEditor('placeholder', {
          document: {
            fileType: this.getFileSuffix(this.config.fileOriginName),
            key: new Date().getTime().toString(), //随机key
            title: this.config.fileOriginName,
            url: this.config.filePath
          },
          documentType: getFileType(this.getFileSuffix(this.config.fileOriginName)),
          forceavetype: 1,
          editorConfig: {
            mode: 'view',
            lang: 'zh',
            customization: {
              forcesave: true
            }
          },
          width: '100%',
          height: '100%'
        });
      });
    },
    //获取文件后缀名
    getFileSuffix(fileName) {
      var index = fileName.lastIndexOf('.');
      //获取后缀
      var ext = fileName.substr(index + 1);
      return ext;
    }
  },
  mounted() {
    this.config = JSON.parse(this.$route.query.config);
    setTimeout(() => {
      this.getEdit();
    }, 2000);
  }
};
</script>
